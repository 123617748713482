import React from 'react';

const Title = () => {
  return (
    <div className="title" id="title">
      <h1>mustafa</h1>
      <h2>100 days.</h2>
      <p>a passion project</p>
    </div>
  )
}

export default Title;