import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyAfgqx7o-uuABzmSFJFVR334Gq6NZWKtLI",
  authDomain: "days-7f82d.firebaseapp.com",
  projectId: "days-7f82d",
  storageBucket: "days-7f82d.appspot.com",
  messagingSenderId: "789863891467",
  appId: "1:789863891467:web:ecc6c1c0858a118ef43d0e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp};