import React, { useState } from 'react';
import ProgressBar from './progressbar';
import { database } from 'firebase';

const UploadForm = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [header, setHeader] = useState('')
  const [pass, setPass] = useState('')

  const types = ['image/png', 'image/jpeg'];

  const handleChange = (e, p) => {
    if (pass == "new") {

      let selected = e.target.files[0];

      if (selected && types.includes(selected.type)) {
        setFile(selected);
        setError('');
      } else {
        setFile(null);
        setError('Please select an image file (png or jpg)');
      }
    } else {
      setError('Please enter correct password');
    }
  };

  return (
    <form>
      <label>
        <input type="file" onChange={handleChange} />
        <span>+</span>
      </label>
      
      <input placeholder="Enter title for pic..." type="text" name={header} onChange={(e)=> setHeader(e.target.value)} />
      <input placeholder="Enter Password." type="text" onChange={p=> setPass(p.target.value)} />
      <div className="output">
        { error && <div className="error">{ error }</div>}
        { file && <div>{ file.name }</div> }
        { file && <ProgressBar file={file} setFile={setFile} header={header} /> }
      </div>
    </form>
  );
}

export default UploadForm;